<template>
	<div>
		<div class="js-copy js-content pd_body">
			<topHeader :type="'white'"></topHeader>
			<div class="ac_organ">
				<div class="ac_organ_header">
					<img src="https://zy.metaera.media/assets/images/active/43.png" />
					<span>我主辦的活動</span>
				</div>
				<div class="ac_organ_status">
					<div
						class="ac_organ_status_item"
						:class="{ ac_organ_status_item_sel: selStatusIndex == 4 }"
						@click="selStatusClick(4)"
					>
						全部
					</div>
					<div
						class="ac_organ_status_item"
						:class="{ ac_organ_status_item_sel: selStatusIndex == 1 }"
						@click="selStatusClick(1)"
					>
						已通过
					</div>
					<div
						class="ac_organ_status_item"
						:class="{ ac_organ_status_item_sel: selStatusIndex == 3 }"
						@click="selStatusClick(3)"
					>
						审核中
					</div>
					<div
						class="ac_organ_status_item"
						:class="{ ac_organ_status_item_sel: selStatusIndex == 2 }"
						@click="selStatusClick(2)"
					>
						未通过
					</div>
					<div
						class="ac_organ_status_item"
						:class="{ ac_organ_status_item_sel: selStatusIndex == 0 }"
						@click="selStatusClick(0)"
					>
						草稿
					</div>
				</div>
				<div class="ac_organ_list">
					<div class="ac_organ_list_top">
						<div class="ac_organ_list_header">
							共
							{{
								selStatusIndex == 4
									? acList.length
									: selStatusIndex == 1
									? acOkList.length
									: selStatusIndex == 2
									? acNoList.length
									: selStatusIndex == 3
									? acIngList.length
									: acTmpList.length
							}}
							场活动</div
						>
						<div
							class="ac_organ_list_header_btn mainbackgroundcolor pointer"
							@click="gotoCreate()"
						>
							创建活动</div
						>
					</div>
					<div class="ac_organ_list_content">
						<div
							v-for="(item, index) in selStatusIndex == 4
								? acList
								: selStatusIndex == 1
								? acOkList
								: selStatusIndex == 2
								? acNoList
								: selStatusIndex == 3
								? acIngList
								: acTmpList"
							:key="index"
						>
							<acItem
								:item="item"
								:type="0"
								@gotoDetail="gotoDetail"
								@gotoEdit="gotoEdit"
								@gotoDel="gotoDel"
							></acItem>
						</div>
					</div>
				</div>
			</div>
			<bottom></bottom>
		</div>
	</div>
</template>

<script>
import { acitvityLists, acitvityDel } from "@/api/home";
import topHeader from "../../components/pc/topHeader.vue";
import bottom from "../../components/pc/bottom.vue";
import acItem from "./ac_item.vue";
import { getUserId } from "@/utils/auth";
import { getToken } from "@/utils/auth";

export default {
	name: "",
	components: {
		topHeader,
		bottom,
		acItem,
	},
	data() {
		return {
			selStatusIndex: 4,
			page: 1,
			size: 99,
			acList: [],
			acOkList: [],
			acIngList: [],
			acNoList: [],
			acTmpList: [],
		};
	},
	created() {
		const hasToken = getToken();
		console.log(hasToken);
		if (hasToken) {
			this.getACList({
				page: this.page,
				size: this.size,
				userid: getUserId(),
			});
		} else {
			this.$router.push({ path: "/" });
			// location.reload();
		}
	},
	methods: {
		selStatusClick(index) {
			this.selStatusIndex = index;
		},
		gotoCreate() {
			this.$router.push({
				path: "/ac_create",
			});
		},
		gotoDetail(item) {
			this.$router.push({
				path: "/ac_detail",
				query: { id: item.id },
			});
		},
		gotoEdit(id) {
			this.$router.push({
				path: "/ac_create",
				query: { id: id },
			});
		},
		gotoDel(id) {
			let obj = { id: id };
			this.$loading.show();
			acitvityDel(obj)
				.then((res) => {
					this.$loading.hide();
					if (res.code == 200) {
						this.loading = false;
						this.getACList({
							page: this.page,
							size: this.size,
							userid: getUserId(),
						});
					} else {
						this.$message({ message: res.message, type: "error" });
					}
				})
				.catch((error) => {
					this.$loading.hide();
					this.$message({ message: error.message, type: "error" });
				});
		},
		getACList(obj) {
			acitvityLists(obj)
				.then((res) => {
					if (res.code == 200) {
						this.loading = false;
						if (obj.page == 1) {
							this.acList = res.data.list;
						} else {
							this.acList = this.articleList.concat(res.data.list);
						}
						for (let i = 0; i < this.acList.length; i++) {
							const item = this.acList[i];
							// 审核结果：0-草稿；1-审核通过；2-审核不通过；3-审核中
							if (item.status == 0) {
								this.acTmpList.push(item);
							} else if (item.status == 1) {
								this.acOkList.push(item);
							} else if (item.status == 2) {
								this.acNoList.push(item);
							} else if (item.status == 3) {
								this.acIngList.push(item);
							}
						}
					} else {
						this.$message({ message: res.message, type: "error" });
					}
				})
				.catch((error) => {
					this.$message({ message: error.message, type: "error" });
				});
		},
	},
};
</script>
<style scoped>
.pd_body {
	height: 100vh;
	overflow-y: scroll;
}
.scroll-container {
	height: 100vh;
	overflow-y: scroll;
}
.js-copy {
	position: absolute;
	top: 0px;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;
	background: white;
}
.ac_organ {
	margin-left: 10%;
	width: 80%;
}
</style>

<style scoped>
.ac_organ_header {
	margin-top: 130px;
	display: flex;
}

.ac_organ_header img {
	width: 24px;
	height: 24px;
}

.ac_organ_header span {
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 22px;
	font-weight: normal;
	line-height: 22px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}
</style>

<!-- ac_organ_status -->
<style scoped>
.ac_organ_status {
	margin-top: 30px;
	display: flex;
	justify-content: space-between;
	height: 50px;
}

.ac_organ_status_item_sel {
	/* 333 */
	background: #333333 !important;
	color: #ffffff !important;
}

.ac_organ_status_item {
	width: 18%;
	border-radius: 10px;
	opacity: 1;
	/* 纯白 */
	background: #ffffff;

	box-sizing: border-box;
	border: 1px solid #ececec;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	/* display: flex; */
	align-content: center;
	text-align: center;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}
</style>

<!-- ac_organ_list -->
<style scoped>
.ac_organ_list {
	margin-top: 40px;
	min-height: 80vh;
}

.ac_organ_list_top {
	display: flex;
	justify-content: space-between;
}

.ac_organ_list_header {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	letter-spacing: 0em;
	align-content: center;
	/* 三级颜色 */
	color: #7a8291;
}

.ac_organ_list_header_btn {
	width: 140px;
	height: 40px;
	border-radius: 20px;
	text-align: center;
	align-content: center;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: normal;
	line-height: 18px;
	text-align: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #ffffff;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
}

.ac_organ_list_content {
	margin-top: 20px;
	opacity: 1;
}
</style>
<style scoped>
@media screen and (max-width: 900px) {
	.ac_organ {
		margin-left: 5%;
		width: 90%;
	}
	.ac_organ_header {
		margin-top: 90px;
	}
}
</style>
